<template>
	<div class="w-full dF fC f1 px-4 pb-4 hide-scrollbar" style="overflow-y: scroll; background:var(--light-bg-gray);">
		<div>
			<FilterDrawer />
			<div class="dF jSB mb-3">
				<div class="dF aC" style="gap: 20px;">
					<h4>Reservations</h4>
					<a-radio-group v-model="reservationList" @change="fetchReservationDetails">
						<a-radio-button value="all">
							All
						</a-radio-button>
						<a-radio-button value="archived">
							Archived
						</a-radio-button>
					</a-radio-group>
				</div>
				<div class="dF aC" style="gap: 20px;">
					<a-input ref="searchInput" v-model="search" placeholder="Search for a reservation..."
						style="width: 250px">
						<a-icon slot="prefix" type="search" />
					</a-input>
					<div class="d-none d-xl-block dash-divider" />
					<a-button @click="newReservation" type="primary" icon="plus">
						ADD NEW RESERVATION
					</a-button>
					<div class="d-none d-xl-block dash-divider" />
					<div @click="showFilter" class="dF aC px-3 py-2 ml-3"
						style="background-color:white; border-radius:4px; border:1px solid #ECEBED; cursor:pointer">
						<div class="mr-2">Filters</div>
						<i class="fe fe-filter" />
					</div>
				</div>
			</div>
			<template>
				<a-card :bodyStyle="{ padding: 0 }" class="my-3">
					<a-table :rowKey="(e) => e.id" :columns="columns" :dataSource="reservations" :loading="loading"
						:pagination="{
							current: currentPage,
							total: totalReservations,
							pageSize: pageSize,
						}" @change="handleChange">
						<template slot="name" slot-scope="reservation">
							{{ reservationName(reservation) }}
						</template>
						<template slot="amount" slot-scope="reservation">
							${{ reservation.amount && reservation.amount.toLocaleString("en-US", {
								useGrouping: false,
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							}) || 0 }} ({{ reservation.currency || "USD" }})
						</template>
						<template slot="status" slot-scope="reservation">
							<span class="badge-offer tT" :class="classLookup(reservation.status).color">
								{{ classLookup(reservation.status).text }}
							</span>
						</template>
						<template slot="purchasers" slot-scope="reservation">
							{{ getPurchaserNames(reservation) }}
						</template>
						<template slot="submittedBy" slot-scope="reservation">
							{{ reservation.createdBy && reservation.createdBy.firstName + " " +
								reservation.createdBy.lastName }}
						</template>
						<template slot="createdAt" slot-scope="reservation">
							{{ formatDate(reservation.createdAt) }}
						</template>
						<template slot="updatedAt" slot-scope="reservation">
							{{ formatDate(reservation.updatedAt) }}
						</template>

						<div slot="actions" slot-scope="reservation" class="dF jSA">
							<a-popover trigger="hover" placement="bottomRight" overlayClassName="popoverStyle">
								<div slot="content">
									<template v-if="reservationList === 'all'">
										<!-- <div v-if="reservation.status === 'submitted'" class="popoverContent dF aC"
											@click="continueReservation(reservation)">
											Continue Reservation
										</div> -->
										<div class="popoverContent dF aC" @click="viewReservation(reservation)">
											View
										</div>
										<div v-if="reservation.status === 'completed' && !requiredMutualRelease"
											class="popoverContent dF aC"
											@click="selectedReservation = reservation; reservationCancellation.reason = ''; voidModal = true">
											Void Reservation
										</div>
										<div v-if="reservation.status === 'completed'" class="popoverContent dF aC"
											@click="createTransaction(reservation)">
											Make a Transaction
										</div>
										<div v-if="['completed', 'void'].includes(reservation.status)"
											class="popoverContent dF aC" @click="archiveReservation(reservation)">
											Archive
										</div>
									</template>
									<template v-else>
										<div class="popoverContent dF aC" @click="editArchive(reservation)">
											Edit
										</div>
									</template>
								</div>
								<div class="more-option-icon pr-4">
									<a-icon style="line-height: 40px" type="more" />
								</div>
							</a-popover>
						</div>
					</a-table>
				</a-card>
			</template>
			<div style="background:var(--light-bg-gray)" v-if="!loading && !reservations.length && !search">
				<EmptyApp :app="$store.state.theApp" text="You don’t have any reservations" />
			</div>
			<a-modal :visible="voidModal" @cancel="voidModal = false; selectedReservation = {};" :centered="true"
				title="Void Reservation" okText="VOID" @ok="voidReservation" :confirmLoading="loading">
				<a-form-model ref="reservationCancellation" :model="reservationCancellation" class="profile"
					v-if="selectedReservation">
					<p>Are you sure you want to void reservation of <strong>{{ reservationName(selectedReservation)
							}}</strong>?</p>
					<div>Doing this will void this entire reservation <br />This will void any documents that are
						pending to be
						signed and remove any supporting documents and also removes any restrictions from the
						<b>underlying
							asset</b> and revert it's status to <b><em>Available</em></b>.
					</div>
					<a-row type="flex" :gutter="20" class="mt-3">
						<a-col :span="24">
							<a-form-model-item label="Reservation Cancellation Reason"
								:rules="req('Please enter reservation cancellation reason')" prop="reason">
								<a-textarea size="large" v-model="reservationCancellation.reason"
									placeholder="Reservation Cancellation Reason" :rows="3" />
							</a-form-model-item>
						</a-col>
					</a-row>
				</a-form-model>
			</a-modal>

			<a-modal :visible="showArchive" :footer="null" centered>
				<template #title>
					Archive Reservation
				</template>

				<div>
					<h6>Would you like to add final notes before this reservation is archived for <strong>{{
						reservationName(selectedReservation) }}</strong>?</h6>
					<a-textarea v-model="archiveNote" class="mt-2" size="large"
						:auto-size="{ minRows: 3, maxRows: 5 }" />
				</div>

				<div style="text-align: right;">
					<a-button @click="onClose" class="mt-4 mr-3" size="large">CANCEL</a-button>
					<a-button @click="confirmArchive" type="primary" size="large" :loading="loading">ARCHIVE</a-button>
				</div>
			</a-modal>
		</div>
	</div>
</template>

<script>
	import EmptyApp from 'bh-mod/components/common/EmptyApp'
	import { formatDate } from "bh-mod";
	import _ from "lodash";
	import moment from "moment";
	import FilterDrawer from '@/components/common/FilterDrawer'

	export default {
		components: {
			EmptyApp,
			FilterDrawer
		},
		data() {
			return {
				loading: false,
				voidModal: false,
				sortedInfo: {
					key: "updatedAt",
					order: "DESC",
				},
				currentPage: 1,
				pageSize: 10,
				totalReservations: 0,
				reservations: [],
				search: '',
				columns: [
					{
						title: "Reservation",
						scopedSlots: { customRender: "name" },
						key: "name",
						sorter: true,
					},
					{
						title: "Amount",
						scopedSlots: { customRender: "amount" },
						key: "amount",
						sorter: true,
					},
					{
						title: "Status",
						scopedSlots: { customRender: "status" },
						key: "status",
						sorter: true,
					},
					{
						title: "Purchaser(s)",
						key: "purchasers",
						scopedSlots: { customRender: "purchasers" }
					},
					{
						title: "Submitted By",
						key: "createdBy",
						scopedSlots: { customRender: "submittedBy" },
						sorter: true,
					},
					{
						title: "Date Created",
						key: "createdAt",
						scopedSlots: { customRender: "createdAt" },
						sorter: true,
					},
					{
						title: "Date Updated",
						key: "updatedAt",
						scopedSlots: { customRender: "updatedAt" },
						sorter: true,
					},
					{
						title: "",
						scopedSlots: { customRender: "actions" },
						key: "actions",
						width: "5%",
					},
				],
				selectedReservation: {},
				reservationCancellation: {
					reason: ''
				},
				reservationList: 'all',
				filterQuery: '',
				showArchive: false,
				archiveNote: '',
			}
		},
		computed: {
			instance() {
				return this.$store.state.instance;
			},

			requiredMutualRelease() {
				return this.$store.state.appData.requiredMutualRelease
			},

			user() {
				return this.$store.state.user.user || {};
			},

			filter() {
				return this.$store.state.appData.filterDrawer.filter
			},
		},
		watch: {
			search() {
				this.searchDebounce();
			},

			filter: {
				deep: true,
				handler(filter) {
					this.filterQuery = ''
					if (filter.status[0] !== "any") {
						filter.status.forEach(status => {
							this.filterQuery += `&status_in[]=${status}`
						})
					}
					this.fetchReservationDetails()
				}
			},
		},
		methods: {
			req: (msg, required = true) => ({ required: required, message: msg }),

			moment, formatDate,

			searchDebounce: _.debounce(function () {
				this.fetchReservationDetails();
			}, 1000),

			fetchReservationDetails() {
				this.currentPage = 1
				this.getReservations();
				this.getTotalReservationCount();
			},

			async getTotalReservationCount() {
				try {
					let searchQuery = "?archived=false";
					if (this.reservationList !== 'all') {
						searchQuery = "?archived=true";
					}
					if (this.search) {
						searchQuery += `&_q=${this.search}`;
					}

					let { data } = await this.$api.get(`reservations/:instance/count${searchQuery}${this.filterQuery}`);
					this.totalReservations = data || 0;
				} catch (err) {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, `An error occurred while fetching broadcast count. Please try again.`))
					}
				}
			},
			async getReservations() {
				this.loading = true;
				try {
					let start = (this.currentPage - 1) * this.pageSize;
					let searchQuery = "?archived=false";
					if (this.reservationList !== 'all') {
						searchQuery = "?archived=true";
					}
					if (this.search) {
						searchQuery = `&_q=${this.search}`;
					}
					let { data } = await this.$api.get(
						`/reservations/:instance${searchQuery}${this.filterQuery}&_start=${start}&_limit=${this.pageSize}&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}`
					);
					this.reservations = data
				} catch (err) {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err, `An error occurred while fetching reservation list. Please try again.`))
					}
				}
				this.loading = false;
			},

			newReservation() {
				let mainTemplates = Object.values(this.$store.state.appData.templates).filter(x => {
					return x.type === 'reservation'
				})
				if (!mainTemplates.length) {
					return this.$message.error('Please setup Reservation Contract Templates before creating a Reservation. Please contact your project administrator.', 5)
				}
				this.$router.push('/newReservation')
			},

			handleChange(pagination, filter, sorter) {
				if (pagination) {
					this.currentPage = pagination.current;
				}
				if (sorter && sorter.columnKey) {
					this.sortedInfo = {
						key: sorter.columnKey,
						order: sorter.order === "descend" ? "DESC" : "ASC",
					};
				}
				this.getReservations();
			},

			async voidReservation() {
				let self = this
				this.$refs.reservationCancellation.validate(async (valid) => {
					if (valid) {
						self.loading = true;
						self.$api.post(`reservations/:instance/${self.selectedReservation.id}/void`, { reason: self.reservationCancellation.reason }).then(() => {
							self.$refs.reservationCancellation.resetFields()
							self.voidModal = false;
							self.$message.success('Selected Reservation voided successfully')
							self.fetchReservationDetails();
						}).catch((err) => {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								self.$message.error(self.$err(err, `An error occurred while voiding reservation. Please try again.`))
							}
						}).finally(() => self.loading = false);
					}
				})
			},

			getPurchaserNames(reservation) {
				let names = {};
				reservation.contacts && reservation.contacts.forEach(p => {
					if (p.email && !names[p.email]) {
						names[p.email] = p.firstName + " " + p.lastName;
					}
				})
				return Object.values(names).join(',\n')
			},

			viewReservation(reservation) {
				this.$router.push(`/reservation/${reservation.id}`)
			},

			classLookup(status) {
				let lookup = {
					submitted: 'bg-black text-white',
					pending: 'text-white',
					signed: 'badge-purple text-white',
					paid: 'bg-orange text-white',
					declined: 'bg-red text-white',
					void: 'bg-red text-white',
					completed: 'badge-green text-white',
				}
				let textLookup = {
					void: 'Voided'
				}
				return {
					color: lookup[status] || 'badge',
					text: textLookup[status] || status,
				}
			},

			reservationName(reservation) {
				const { product = {} } = reservation;
				const { unit, floor, lot } = product;

				if (this.instance.productType === 'highrise') {
					if (floor && unit) {
						return `${floor.name} (#${unit.unitNumber})`;
					}
					return unit ? `Unit: ${unit.name || 'N/A'}` : 'Unit N/A';
				} else {
					if (lot) {
						const blockInfo = lot.block ? ` (Block ${lot.block})` : '';
						const unitGroupInfo = unit?.unitGroup ? `${unit.unitGroup.name || ''} ${unit.name || ''} - ${unit.unitGroup.size || ''}' ${unit.unitGroup.type || ''}` : '';
						return `Lot ${lot.name}${blockInfo} (${unitGroupInfo})`;
					}
					return unit ? `Unit: ${unit.name || 'N/A'}` : 'Lot N/A';
				}
			},

			createTransaction(reservation) {
				this.$router.push(`/new?reservation=${reservation.id}`)
			},

			continueReservation(reservation) {
				this.$router.push(`/reservation/edit/${reservation.id}`)
			},

			showFilter() {
				this.$store.commit('OPEN_FILTER')
			},

			archiveReservation(reservation) {
				this.showArchive = true
				this.selectedReservation = reservation
				this.archiveNote = ''
			},

			onClose() {
				this.showArchive = false
				this.selectedReservation = {}
				this.archiveNote = ''
			},

			confirmArchive() {
				let { notes } = this.selectedReservation
				if (!notes) notes = []

				notes.push({
					subject: 'Archived on:  ' + formatDate(Date.now()),
					content: this.archiveNote,
					updatedAt: Date.now(),
					id: Date.now() + '',
					createdBy: this.user.id || '',
				})
				this.loading = true;
				this.$api.put(`/reservations/:instance/${this.selectedReservation.id}`, { archived: true, notes }).then(() => {
					this.fetchReservationDetails()
					this.onClose();
				}).catch((err) => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				}).finally(() => {
					this.loading = false;
				})
			},

			editArchive(reservation) {
				let reservationName = this.reservationName(reservation);
				this.$confirm({
					title: 'Edit Reservation',
					content: h => <div>Do you want to edit this reservation <b>{reservationName}</b>? This reservation will be unarchived. Are you sure you want to continue?</div>,
					okText: 'Continue',
					okType: 'primary',
					cancelText: 'Cancel',
					centered: true,
					confirmLoading: this.loading,
					onOk: async () => {
						this.loading = true;
						try {
							await this.$api.put(`/reservations/:instance/${reservation.id}`, { archived: false })
							this.$router.push(`/reservation/${reservation.id}`)
						} catch (err) {
							if (!err || !err.response || !err.response.status || err.response.status !== 400) {
								this.$message.error(this.$err(err))
							}
						}
						this.loading = false;
					}
				});
			},
		},

		created() {
			this.$store.dispatch('FETCH_INVENTORY_SETTINGS')
			this.fetchReservationDetails();
		},
	}

</script>

<style lang="scss" scoped>
	.popoverContent {
		height: max-content;
		line-height: 30px;
		padding: 0 10px;
	}

	.popoverContent:hover {
		background-color: var(--off-white-light);
		color: var(--primary);
		cursor: pointer;
	}
</style>

<style lang="scss">
	.popoverStyle .ant-popover-inner-content {
		padding: 5px;
		background-color: white;
		border-radius: 20px;
	}
</style>
