<template>
	<div>
		<a-drawer :zIndex="1000" :width="500" @close="onClose" :visible="filterDrawer.visible"
			:wrapStyle="{ overflow: 'auto', }">
			<bhLoading :show="loading" />
			<div class="h-full dF fC jSB" style="margin-bottom: 60px;">
				<div class="f1">
					<h5>Filters</h5>
					<div v-show="filterApplied" style="display:inline-block">
						<div @click="clearFilter" class="dF aC mt-3 py-2 px-4"
							style="border-radius:4px; border:1px solid #ECEBED; background-color:white; cursor:pointer">
							<div class="mr-3">Clear Filter</div><a-icon style="color:var(--danger)" type="delete" />
						</div>
					</div>
					<div class="mt-5">
						<!-- <div class="mt-3" v-if="instance.productType === 'lowrise'">
							<h6>Select Lot(s):</h6>
							<a-row :gutter="[16, 16]">
								<a-col>
									<a-select style="width: 100%" v-model="filter.lots" mode="multiple">
										<a-select-option v-for="lot in lots" :key="lot.id" :value="lot.id">
										Lot {{ lot.name }}</a-select-option>
									</a-select>
								</a-col>
							</a-row>
						</div>
						<div class="mt-3" v-else>
							<h6>Select Unit(s):</h6>
							<a-row :gutter="[16, 16]">
								<a-col>
									<a-select style="width: 100%" v-model="filter.units" mode="multiple">
										<a-select-option v-for="unit in units" :key="unit.id" :value="unit.id">Unit {{
											unit.unitNumber }}</a-select-option>
									</a-select>
								</a-col>
							</a-row>
						</div> -->
						<div class="mt-3">
							<h6>Reservation Status:</h6>
							<a-row :gutter="[16, 16]">
								<a-col :span="6" v-for="(status, index) in statusList" :key="index"
									:value="status.value">
									<div @click="changeStatus(status.value)"
										:style="filter.status && filter.status.length && filter.status.includes(status.value) ? 'background-color: #CAC3D0;' : ''"
										class="py-1 px-2 filter-options"
										style=" border: #d0c9d6 1px solid; border-radius: 6px; width: 100%; cursor: pointer; text-align: center; ">
										{{ status.name }}
									</div>
								</a-col>
							</a-row>
						</div>
					</div>
				</div>
				<div
					style="position: absolute; left: 0px; bottom: 0px; width: 100%; border-top: 1px solid rgb(233, 233, 233); padding: 10px 16px; background: rgb(255, 255, 255); text-align: right;">
					<div class="dF jE">
						<div class="dF ml-3">
							<a-button size="large" class="mr-3 cancel-button" @click="onClose">CANCEL</a-button>
							<a-button size="large" @click="submit" type="primary">APPLY FILTER</a-button>
						</div>
					</div>
				</div>
			</div>
		</a-drawer>
	</div>
</template>

<script>
	import moment from 'moment'
	import bhLoading from 'bh-mod/components/common/Loading'
	export default {
		components: { bhLoading },
		data() {
			return {
				loading: false,
				filter: {},
				statusList: [{ name: 'Any', value: 'any' }, { name: 'Submitted', value: 'submitted' }, { name: 'Pending', value: 'pending' }, { name: 'Signed', value: 'signed' }, { name: 'Paid', value: 'paid' }, { name: 'Completed', value: 'completed' }, { name: 'Void', value: 'void' }]
			}
		},
		watch: {
			visible: {
				handler(val) {
					if (val) {
						this.filter = JSON.parse(JSON.stringify(this.filterDrawer.filter))
					}
				}
			}
		},
		computed: {
			instance() {
				return this.$store.state.instance
			},

			lots() {
				const data = this.$store.state.appData.lots || [];

				return data.sort((a, b) => {
					if (parseInt(a.name) < parseInt(b.name)) return -1;
					if (parseInt(a.name) > parseInt(b.name)) return 1;
					else return 0;
				})
			},

			units() {
				const data = this.$store.state.appData.units || [];

				return data.sort((a, b) => {
					if (parseInt(a.unitNumber) < parseInt(b.unitNumber))
						return -1;
					if (parseInt(a.unitNumber) > parseInt(b.unitNumber))
						return 1;
					else return 0;
				})
			},

			filterDrawer() {
				return this.$store.state.appData.filterDrawer
			},
			visible() {
				return this.filterDrawer.visible
			},
			filterApplied() {
				if (this.filterDrawer.filter.lots.length !== 0 || this.filterDrawer.filter.units.length !== 0 || this.filterDrawer.filter.status[0] !== "any") {
					return true
				}
				return false
			}
		},
		methods: {
			moment,

			submit() {
				this.$store.commit("UPDATE_FILTER", this.filter);
				this.onClose();
			},

			clearFilter() {
				this.$store.commit('RESET_DEFAULT_FILTER')
				this.$store.commit('CLOSE_FILTER')
			},
			onClose() {
				this.$store.commit('CLOSE_FILTER')
			},

			changeStatus(status) {
				if (status != "any") {
					if (
						this.filter.status.length == 1 &&
						this.filter.status[0] == "any"
					) {
						this.filter.status.splice(0, 1);
					}
					if (!this.filter.status.includes(status)) {
						this.filter.status.push(status);
					} else {
						let index = this.filter.status.findIndex((x) => x == status);
						this.filter.status.splice(index, 1);
					}
				} else {
					this.filter.status = ["any"];
				}
				if (this.filter.status.length == 0) {
					this.filter.status = ["any"];
				}
			},
		},
	}
</script>

<style>
	.range-display .ant-tag-blue {
		color: black !important;
	}

	.collapse-display.ant-collapse {
		border: none !important;
	}

	.collapse-display.ant-collapse>.ant-collapse-item {
		border-bottom: none !important;
	}

	.collapse-display .ant-collapse-header {
		padding: none !important;
	}

	.collapse-display.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
		padding: 0 !important;
	}

	.collapse-display .ant-collapse-content {
		border: none !important;
	}

	.cancel-button.ant-btn:hover {
		border-color: #ECE9F1 !important;
	}
</style>
<style scoped>
	.filter-options:hover {
		background-color: #ECE9F1;
	}

	.hide-display {
		height: 0;
		transition: height .3s ease-in-out;
		overflow: hidden;
	}

	.show-display {
		height: 200px;
	}
</style>
